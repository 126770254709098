// Generated by Framer (ddd30d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IqywhLM06", "j_m3JsXzY"];

const serializationHash = "framer-mPJc1"

const variantClassNames = {IqywhLM06: "framer-v-1kkswow", j_m3JsXzY: "framer-v-7sc159"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 2, ease: [0, 0.38, 0.37, 0.99], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableEnumMap = {"Space Around": "space-around", "Space Between": "space-between", "Space Evenly": "space-evenly", Center: "center", End: "flex-end", Start: "flex-start"}

const humanReadableVariantMap = {"Variant 1": "IqywhLM06", "Variant 2": "j_m3JsXzY"}

const getProps = ({distribute, height, id, width, ...props}) => { return {...props, ShqeVtVCd: humanReadableEnumMap[distribute] ?? distribute ?? props.ShqeVtVCd ?? "center", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "IqywhLM06"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;distribute?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ShqeVtVCd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "IqywhLM06", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1kkswow", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IqywhLM06"} ref={ref ?? ref1} style={{"--yktru8": ShqeVtVCd, ...style}} {...addPropertyOverrides({j_m3JsXzY: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-15izuku"} layoutDependency={layoutDependency} layoutId={"SBbONWsXB"}><motion.div className={"framer-bgfoxe"} data-framer-name={"Light Line"} layoutDependency={layoutDependency} layoutId={"OZuWfh11i"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mPJc1.framer-1itgdu8, .framer-mPJc1 .framer-1itgdu8 { display: block; }", ".framer-mPJc1.framer-1kkswow { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 2px; justify-content: var(--yktru8); overflow: visible; padding: 0px; position: relative; width: 24px; }", ".framer-mPJc1 .framer-15izuku { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 2px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 24px; }", ".framer-mPJc1 .framer-bgfoxe { flex: 1 0 0px; height: 100%; overflow: visible; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mPJc1.framer-1kkswow, .framer-mPJc1 .framer-15izuku { gap: 0px; } .framer-mPJc1.framer-1kkswow > *, .framer-mPJc1 .framer-15izuku > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mPJc1.framer-1kkswow > :first-child, .framer-mPJc1 .framer-15izuku > :first-child { margin-left: 0px; } .framer-mPJc1.framer-1kkswow > :last-child, .framer-mPJc1 .framer-15izuku > :last-child { margin-right: 0px; } }", ".framer-mPJc1.framer-v-7sc159 .framer-15izuku { flex: 1 0 0px; width: 1px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"j_m3JsXzY":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ShqeVtVCd":"distribute"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKN87c05eL: React.ComponentType<Props> = withCSS(Component, css, "framer-mPJc1") as typeof Component;
export default FramerKN87c05eL;

FramerKN87c05eL.displayName = "Abstract/Scroll Light Line";

FramerKN87c05eL.defaultProps = {height: 2, width: 24};

addPropertyControls(FramerKN87c05eL, {variant: {options: ["IqywhLM06", "j_m3JsXzY"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, ShqeVtVCd: {defaultValue: "center", options: ["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"], optionTitles: ["Start", "Center", "End", "Space Between", "Space Around", "Space Evenly"], title: "Distribute", type: ControlType.Enum}} as any)

addFonts(FramerKN87c05eL, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})